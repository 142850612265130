import { DownloadOutlined, OpenInNew } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { getOrganizations, getOrganizationsCsv, getOrganizationCsv } from '../../services/api'
import { IOrganization, IOrganizationFilters } from './types'
import {getSeedStageOptions} from '../../utils/tool'
import * as _ from 'lodash'
import './OrganizationTable.css'

const initialFilters = {
  name: [],
  type: [],
  employees: [],
  lastFundingType: [],
  minAmount: undefined,
  maxAmount: undefined,
  icoStatus: []
}

const OrganizationsTable = () => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [filters, setFilters] = useState<IOrganizationFilters>(initialFilters)

  // FUNCTIONS ----------------->

  const getOrganizationsList = useCallback(async (): Promise<void> => {
    const organizationsRes = await getOrganizations(filters)
    if (organizationsRes.status === 200) {
      setOrganizations(organizationsRes.data)
    }
  }, [filters])

  const getFieldFilterOptions = useCallback(
    (fieldName: 'name' | 'type' | 'employees' | 'icoStatus' | 'lastFundingType') => {
      const fieldFilterOptions = organizations
        .map((organization) => organization?.[fieldName])
        .filter((item, i, arr) => !!item && arr.indexOf(item) === i)
        .sort()
      return fieldFilterOptions
    },
    [organizations]
  )

  const updateFilter = useCallback((name: string, e: any): void => {
    const value: string[] | number | Date = e.target.value
    setFilters((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const getOrganizationsCsvFile = async () => {
    const res = await getOrganizationsCsv(filters)
    if (res.status === 200) {
      const csv = res.data
      const blob = new Blob([csv], { type: 'text/csv' })
      const a = document.createElement('a')
      a.download = "organizations" + '.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  const getOrganizationCsvFile = async (organizationId) => {
    const res = await getOrganizationCsv(organizationId)
    if (res.status === 200) {
      const csv = res.data
      const blob = new Blob([csv], { type: 'text/csv' })
      const a = document.createElement('a')
      a.download = "organizations-" + organizationId + '-.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  // EFFECTS ------------------->

  useEffect(() => {
    getOrganizationsList()
  }, [getOrganizationsList, filters])

  // RENDERS ------------------->

  console.log('HEREE')
  console.log(organizations)

  const renderFilters = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="organizations-label">Organizations</InputLabel>
            <Select
              id="organizations"
              labelId="organizations-label"
              multiple
              // label="Organizations"
              value={filters?.name || []}
              variant="filled"
              sx={{ width: '100%' }}
              onChange={(e) => {
                updateFilter('name', e)
              }}
            >
              {getFieldFilterOptions?.('name').map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={filters?.name && filters?.name?.indexOf(option) > -1} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="organizations-type-label">Type</InputLabel>
            <Select
              id="organization-type"
              multiple
              labelId="organizations-type-label"
              value={filters?.type || []}
              variant="filled"
              sx={{ width: '100%' }}
              onChange={(e) => {
                updateFilter('type', e)
              }}
            >
              {getFieldFilterOptions?.('type').map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={filters?.type && filters?.type?.indexOf(option) > -1} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="organization-employees-range-label">Employees Range</InputLabel>
            <Select
              id="organization-employees-range"
              labelId="organization-employees-range-label"
              multiple
              inputProps={{ inputMode: 'numeric' }}
              value={filters?.employees || ''}
              variant="filled"
              sx={{ width: '100%', marginRight: 1 }}
              onChange={(e) => {
                updateFilter('employees', e)
              }}
            >
              {getFieldFilterOptions?.('employees').map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={filters?.employees && filters?.employees?.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="seed-stage-label">Seed stage</InputLabel>
            <Select
              id="seed-stage"
              labelId="seed-stage-label"
              multiple
              label="Stage"
              value={filters?.lastFundingType || []}
              variant="filled"
              sx={{ width: '100%' }}
              onChange={(e) => {
                updateFilter('lastFundingType', e)
              }}
            >
              {getSeedStageOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={filters?.lastFundingType && filters?.lastFundingType?.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="status-ico-label">Status Ico</InputLabel>
            <Select
              id="status-ico"
              labelId="status-ico-label"
              multiple
              label="Status ICO"
              value={filters?.icoStatus || ''}
              variant="filled"
              sx={{ width: '100%' }}
              onChange={(e) => {
                updateFilter('icoStatus', e)
              }}
            >
              {getFieldFilterOptions?.('icoStatus').map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={filters?.icoStatus && filters?.icoStatus?.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Stack flexDirection="row">
            <TextField
              id="organization-seed-amount-min"
              // multiple
              inputProps={{ inputMode: 'numeric' }}
              label="Min Seed amount"
              value={filters?.minAmount || ''}
              variant="filled"
              sx={{ flex: 1, marginRight: 1 }}
              onChange={(e) => {
                updateFilter('minAmount', e)
              }}
            />

            <TextField
              id="organization-seed-amount-max"
              // multiple
              inputProps={{ inputMode: 'numeric' }}
              label="Max Seed amount"
              value={filters?.maxAmount || ''}
              variant="filled"
              sx={{ flex: 1 }}
              onChange={(e) => {
                updateFilter('maxAmount', e)
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack
            sx={{ height: '100%' }}
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={() => getOrganizationsCsvFile()}
              color="primary"
              variant="outlined"
              startIcon={<DownloadOutlined />}
            >
              CSV
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>CEO Contact</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Employees</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Update Date</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Seed Stage</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Seed Amount</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Status ICO</TableCell>
          <TableCell align="center" sx={{ fontWeight: 'bold' }}>
            Web
          </TableCell>
          <TableCell align="center" sx={{ fontWeight: 'bold' }}>
            CSV
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = () => {
    return (
      <TableBody>
        {organizations.map((org) => (
          <TableRow key={org._id}>
            <TableCell>{org.name}</TableCell>
            <TableCell>
              {!!org.description && (
                <Tooltip title={org.description}>
                  <span>{_.truncate(org.description, { length: 22 })}</span>
                </Tooltip>
              )}
            </TableCell>
            <TableCell>{org.type}</TableCell>
            <TableCell>{org.ceoContact}</TableCell>
            <TableCell>{org.employees}</TableCell>
            <TableCell>{org?.updateDate ? format(new Date(org?.updateDate), 'Pp') : ''}</TableCell>
            <TableCell>{org?.lastFundingType}</TableCell>
            <TableCell>{org.seedAmount}</TableCell>
            <TableCell>{org.icoStatus}</TableCell>
            <TableCell align="center">
              <IconButton color="primary" onClick={() => window.open(org?.socialMedia?.website, '_blank')}>
                <OpenInNew />
              </IconButton>
            </TableCell>
            <TableCell align="center">
            <IconButton onClick={() => getOrganizationCsvFile(org._id)}>
                <DownloadOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <Container>
      {renderFilters()}
      <TableContainer component={Paper} sx={{ marginTop: 2, padding: 2 }}>
        <Table className="table">
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </Container>
  )
}

export default OrganizationsTable
