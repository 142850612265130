import { CheckCircleOutline } from '@mui/icons-material'
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { createNewUser, getUsers, toggleUserStatus } from '../../services/api'
import { IStatusModalData } from '../ExecutionsTable/types'
import StatusModal from './StatusModal'
import { IUser } from './types'

const SettingsTable = () => {
  const [users, setUsers] = useState<IUser[]>([])
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false)
  const [statusModalData, setStatusModalData] = useState<IStatusModalData>()
  const [newUserName, setNewUserName] = useState<string>('')
  const [newUserEmail, setNewUserEmail] = useState<string>('')

  // FUNCTIONS ---------------->

  const openStatusModal = (email: string, name: string, active: boolean): void => {
    setStatusModalData({
      name,
      status: 'unsent',
      active,
      onConfirm: () => toggleStatus(email, active)
    })
    setIsStatusModalOpen(true)
  }

  const closeStatusModal = (): void => {
    if (statusModalData?.status !== 'unsent') {
      (async () => {
        const _users = await getUsers()
        if (_users.status === 200) {
          setUsers(_users.data)
        }
      })()
    }
    setIsStatusModalOpen(false)
    setStatusModalData(undefined)
  }

  const toggleStatus = async (id: string, currentStatus: boolean): Promise<void> => {
    setStatusModalData((prev) => ({ ...prev, status: 'sending' }))
    const res = await toggleUserStatus(id, !currentStatus)
    if (res?.status === 200) {
      setStatusModalData((prev) => ({ ...prev, status: 'done' }))
    }
  }

  const handleSaveNewUser = async () : Promise<void> => {
    const data = {name: newUserName, email: newUserEmail};
    const res = await createNewUser(data);
    if (res?.status === 201) {
      const _users = await getUsers()
        if (_users.status === 200) {
          setUsers(_users.data)
        }
      setNewUserEmail("");
      setNewUserName("");
    }
  };

  // EFFECTS ------------------->

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const res = await getUsers()
      if (res.status === 200 && isMounted) {
        setUsers(res.data)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [])

  // RENDERS ------------------->

  const renderTableHead = (): ReactNode => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Mail</TableCell>
          <TableCell>Active</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = (): ReactNode => {
    return (
      <TableBody>
        {users.map((user) => (
          <TableRow key={user._id}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              <ToggleButton
                value="check"
                selected={user.active}
                onChange={() => openStatusModal(user.email, user.name, user.active)}
                color="primary"
              >
                <CheckCircleOutline />
              </ToggleButton>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <TextField
              variant='standard'
              size="small"
              sx={{width: '100%'}}
              value={newUserName}
              onChange={(e) => {
                const value = e.target.value
                setNewUserName(value)
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              variant='standard'
              size="small"
              sx={{width: '100%'}}
              value={newUserEmail}
              onChange={(e) => {
                const value = e.target.value
                setNewUserEmail(value)
              }}
            />
          </TableCell>
          <TableCell>
            <Button onClick={handleSaveNewUser} color="primary" variant='contained'>
              Save
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return (
    <Container>
      <StatusModal
        open={isStatusModalOpen}
        onClose={closeStatusModal}
        active={statusModalData?.active}
        status={statusModalData?.status}
        name={statusModalData?.name}
        onConfirm={statusModalData?.onConfirm}
      />
      <TableContainer component={Paper} sx={{ marginTop: 2, padding: 2 }}>
        <Table>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </Container>
  )
}

export default SettingsTable
