import axios from 'axios'
import { IOrganization, IOrganizationFilters } from '../components/OrganizationsTable/types'
import { BACKEND_URI } from '../config/constants'

export const getLastExecutions = async (): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/lastexecutions`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const executeJob = async (jobId: string): Promise<any> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${BACKEND_URI}/job/execute/${jobId}`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const getExecutionCsv = async (executionId: string): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/executions/${executionId}/csv`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const getHistoricalExecutions = async (): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/executions`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const togglePluginStatus = async (jobId: string, status): Promise<any> => {
  try {
    const response = await axios({
      method: 'put',
      url: `${BACKEND_URI}/jobs/${jobId}/${status}`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const toggleUserStatus = async (userId: string, status): Promise<any> => {
  try {
    const response = await axios({
      method: 'put',
      url: `${BACKEND_URI}/settings/${userId}/${status}`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const createNewUser = async (data): Promise<any> => {
  try {
    const response = await axios({
      method: 'post',
      url: `${BACKEND_URI}/settings/user`,
      data
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const getUsers = async (): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/settings/users`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}


const setFiltersOrganizationQueryString = (filters) => {
  const activeFilters: {[k: string]: any} = {}
  if (filters.name.length > 0){
   activeFilters.name = filters.name[0]
  }
  if (filters.employees.length > 0){
    activeFilters.employees = filters.employees[0]
  }
  if(filters.type.length > 0){
    activeFilters.type = filters.type[0]
  }
  if(filters.lastFundingType.length > 0){
    activeFilters.lastFundingType = filters.lastFundingType[0]
  }
  if(filters.icoStatus.length > 0){
    activeFilters.icoStatus = filters.icoStatus[0]
  }
  return activeFilters
}


export const getOrganizations = async (filters): Promise<any> => {
  try {
    const queries = setFiltersOrganizationQueryString(filters)
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/organizations`,
      params: {
        name: queries.name != '' ? queries.name : '',
        type: queries.type != '' ? queries.type : '',
        employees: queries.employees != '' ? queries.employees : '',
        lastFundingType: queries.lastFundingType != '' ? queries.lastFundingType : '',
        icoStatus: queries.icoStatus != '' ? queries.icoStatus : '',
        minFundingAmount: filters.minAmount != '' ? filters.minAmount : '',
        maxFundingAmount: filters.maxAmount != '' ? filters.maxAmount : ''
      }
    })
    console.log(response);
    //const responseFiltered = filterOrganizations(response.data, filters);
    //response.data = responseFiltered;
    return response;
    } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const getOrganizationsCsv = async (filters): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/exportCsvOrganizations`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}

export const getOrganizationCsv = async (organizationId): Promise<any> => {
  try {
    const response = await axios({
      method: 'get',
      url: `${BACKEND_URI}/exportCsvOrganization/${organizationId}`
    })
    return response
  } catch (err) {
    console.error('err', err)
    return 0
  }
}