import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: '#01B5FE',
    },
    secondary: {
      main: '#F05C79',
    },
  },
  typography: {
    h1: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    }
  }
};

export const theme = createTheme(themeOptions);