import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Login } from '../login'
import { useAuthContext } from '../../contexts/authContext'
import { Home } from '../home'
import { HistorialExecution } from '../historialExecution'
import { Settings } from '../settings'
import Organizations from '../organizations'

export const Routes: React.FC = () => {
  const { isSignedIn } = useAuthContext();
  
  const location = useLocation();
  const getPathDepth = (location: any) => {
    let pathArr = location.pathname.split("/");
    pathArr = pathArr.filter((n: string) => n !== "");
    return pathArr.length;
  }
  const [prevDepth, setPrevDepth] = React.useState<number>(getPathDepth(location))

  const currentKey = location.pathname.split("/")[1] || "/";
  //Specify the duration of the animation (on enter and on exit)
  const timeout = { enter: 800, exit: 400 };

  React.useEffect( () => {
    setPrevDepth(getPathDepth(location));
  }, [location])

  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  // https://v5.reactrouter.com/web/example/auth-workflow
  function AuthenticatedRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        isSignedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (
    // https://medium.com/@ipenywis/slide-page-css-transition-on-react-with-react-router-38373da5e608
    <TransitionGroup component="div" className="transition">
      <CSSTransition
        key={currentKey}
        timeout={timeout}
        classNames="pageSlider"
        mountOnEnter={false}
        unmountOnExit={true}
      >
        <div
          className={
            getPathDepth(location) - prevDepth >= 0
              ? "left"
              : "right"
          }
        >
          <Switch location={location}>
            { isSignedIn ?
              <Redirect exact from="/" to="/home" />:
              <Route component={Login} exact path="/" />
            }
            { isSignedIn ?
              <Redirect exact from="/login" to="/home" />:
              <Route component={Login} exact path="/login" />
            }

            { 
            <><AuthenticatedRoute exact path="/home">
                <Home />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/historialExecution">
                  <HistorialExecution />
                </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/settings">
                 <Settings />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/organizations">
                <Organizations />
              </AuthenticatedRoute>
            </>
}
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}
