import { ToastProvider } from 'react-toast-notifications'
// styles
import './assets/css/app.css'
// components
import { Routes } from './pages/routes'
import { AuthContextProvider } from './contexts/authContext'
import { ThemeProvider } from '@mui/material'
import { theme } from './contexts/themeContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function App() {
  return (
    <div className="App">
      <ToastProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthContextProvider>
              <Routes />
          </AuthContextProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ToastProvider>
    </div>
  )
}

export default App
