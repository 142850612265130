import SettingsTable from '../components/SettingsTable/SettingsTable'
import Page from '../components/Page'

export const Settings = () => {
  return (
    <Page id="settings">
      <SettingsTable />
    </Page>
  )
}
