import { Container } from '@mui/material'
import { Header } from './header/header'

const Page = ({ children, id }) => {
  return (
    <section id={id} className="page">
      <Header />
      <Container>{children}</Container>
    </section>
  )
}

export default Page
