import HistoricalExecutionsTable from '../components/HistoricalExecutionsTable/HistoricalExecutionsTable'
import Page from '../components/Page'

export const HistorialExecution = () => {
  return (
    <Page id="historical-executions">
      <HistoricalExecutionsTable />
    </Page>
  )
}
