import { CheckCircleOutline } from '@mui/icons-material'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { ClipLoader } from 'react-spinners'
import { theme } from '../../contexts/themeContext'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const ExecuteModal = ({ open, onClose, status, name, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack direction="row" justifyContent="center">
          {status === 'unsent' && (
            <Typography id="modal-modal-title" variant="body1" component="h3">
              Run manual execution on {name}
            </Typography>
          )}
          {status === 'sending' && <ClipLoader color={theme.palette.text.primary} />}
          {status === "done" && <CheckCircleOutline fontSize='large' color='primary'/>}
        </Stack>
        <Stack sx={{ marginTop: 4 }} justifyContent="center" direction="row" spacing={2}>
          {status === "unsent" && <Button onClick={onConfirm} variant="outlined" color="primary">
            Run
          </Button>}
          <Button onClick={onClose} variant="outlined" color="error">
            {status === "unsent" ? "Cancel" : "Close"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ExecuteModal
