import { CheckCircleOutline, DownloadOutlined } from '@mui/icons-material'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  ToggleButton,
  IconButton
} from '@mui/material'
import { format } from 'date-fns'
import { ReactNode, useEffect, useState } from 'react'
import { Container, Table } from 'reactstrap'
import { executeJob, getExecutionCsv, getLastExecutions, togglePluginStatus } from '../../services/api'
import { IExecutionRow } from '../HistoricalExecutionsTable/types'
import ExecuteModal from './ExecuteModal'
import StatusModal from './StatusModal'
import { IExecuteModalData, IStatusModalData } from './types'

const ExecutionsTable = () => {
  const [executions, setExecutions] = useState<IExecutionRow[]>([])
  const [isExecuteModalOpen, setIsExecuteModalOpen] = useState<boolean>(false)
  const [executeModalData, setExecuteModalData] = useState<IExecuteModalData>()
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false)
  const [statusModalData, setStatusModalData] = useState<IStatusModalData>()

  // FUNCTIONS ----------------->

  const openExecuteModal = (name: string): void => {
    setExecuteModalData({ name, status: 'unsent', onConfirm: () => runExecution(name) })
    setIsExecuteModalOpen(true)
  }

  const closeExecuteModal = (): void => {
    if (executeModalData?.status !== 'unsent') {
      (async () => {
        const _executions = await getLastExecutions()
        setExecutions(_executions.data)
      })()
    }
    setIsExecuteModalOpen(false)
    setExecuteModalData(undefined)
  }

  const openStatusModal = (name: string, active: boolean): void => {
    setStatusModalData({ name, status: 'unsent', active, onConfirm: () => toggleStatus(name, active) })
    setIsStatusModalOpen(true)
  }

  const closeStatusModal = (): void => {
    if (statusModalData?.status !== 'unsent') {
      (async () => {
        const _executions = await getLastExecutions()
        setExecutions(_executions.data)
      })()
    }
    setIsStatusModalOpen(false)
    setStatusModalData(undefined)
  }

  const runExecution = async (name: string): Promise<void> => {
    setExecuteModalData((prev) => ({ ...prev, status: 'sending' }))
    const res = await executeJob(name)
    if (res?.status === 200) {
      setExecuteModalData((prev) => ({ ...prev, status: 'done' }))
    }
  }

  const toggleStatus = async (name: string, currentStatus: boolean) : Promise<void> => {
    setStatusModalData(prev => ({...prev, status: "sending"}));
    const res = await togglePluginStatus(name, !currentStatus);
    if (res?.status === 200) {
      setStatusModalData(prev => ({...prev, status: 'done'}));
    }
  }

  const getCsvFile = async (id, name) => {
    const res = await getExecutionCsv(id)
    if (res.status === 200) {
      const csv = res.data
      const blob = new Blob([csv], { type: 'text/csv' })
      const a = document.createElement('a')
      a.download = (name || id) + '.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  // EFFECTS ----------------->

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      const _executions = await getLastExecutions()
      if (isMounted) {
        setExecutions(_executions.data)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [])

  // RENDERS ---------------->

  const renderTableHead = (): ReactNode => {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Web</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Last Run</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">
            Status Last Run
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Reason</TableCell>
          <TableCell align="center" sx={{ fontWeight: 'bold' }}>
            Manual Execution
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Active</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Result</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = (): ReactNode => {
    return (
      <TableBody>
        {executions.map(({ name, execution, active }) => (
          <TableRow key={name}>
            <TableCell>{name}</TableCell>
            <TableCell>{!!execution?.endAt && format(new Date(execution?.endAt), 'Pp')}</TableCell>
            <TableCell align="center">{execution?.result ? '🟢' : '🔴'}</TableCell>
            <TableCell>{execution?.reason || '--'}</TableCell>
            <TableCell align="center">
              <Button onClick={() => openExecuteModal(name)} variant="outlined">
                Run
              </Button>
            </TableCell>
            <TableCell>
              <ToggleButton value="check" selected={active} onChange={() => {openStatusModal(name, active)}} color="primary">
                <CheckCircleOutline />
              </ToggleButton>
            </TableCell>
            <TableCell>
              <IconButton onClick={() => getCsvFile(execution._id, name)}>
                <DownloadOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <Container>
      <ExecuteModal
        open={isExecuteModalOpen}
        onClose={closeExecuteModal}
        status={executeModalData?.status}
        name={executeModalData?.name}
        onConfirm={executeModalData?.onConfirm}
      />

      <StatusModal
        open={isStatusModalOpen}
        onClose={closeStatusModal}
        active={statusModalData?.active}
        status={statusModalData?.status}
        name={statusModalData?.name}
        onConfirm={statusModalData?.onConfirm}
      />
      <TableContainer component={Paper} sx={{ marginTop: 2, padding: 2 }}>
        <Table>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </Container>
  )
}

export default ExecutionsTable
