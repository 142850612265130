import { Button } from "@mui/material";
import { useHistory } from "react-router-dom"

// import { useAuthContext } from '../../contexts/authContext'

export const MyMenu = () => {
  const history = useHistory();
  // const { signOut } = useAuthContext()

  // https://www.npmjs.com/package/react-google-login
  // const logout = async () => {
  //   // update state, do the transition
  //   signOut()
  //   history.push("/login")
  // }

  const handleClickOnMain = () => {
    history.push("/home")
  };

  const handleClickOnHistoricalExecution = () => {
    history.push("/historialExecution")
  };

  const handleClickOnSettings = () => {
    history.push("/settings")
  };

  const handleClickOnOrganizations = () => {
    history.push("/organizations")
  }

  // const handleClickOnResult = () => {
  //   history.push("/result")
  // };

  return (
        <div>
          <Button sx={{mr: 2}} onClick={handleClickOnMain} color="primary" variant="text">
            Main
          </Button>
          <Button sx={{mr: 2}} onClick={handleClickOnHistoricalExecution} color="primary" variant="text">
            Historical Executions
          </Button>

          <Button sx={{mr: 2}} onClick={handleClickOnOrganizations} color="primary" variant="text">
            Organizations
          </Button>
          <Button sx={{mr: 2}} onClick={handleClickOnSettings} color="primary" variant="text">
            Settings
          </Button>
        </div>  
  )

}
