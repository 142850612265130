import { useHistory } from 'react-router-dom'

import { useAuthContext } from '../../contexts/authContext'
import { MyMenu } from './mymenu'
import thinkAndDevMobileLogo from '../../assets/img/think_dev_mobile.png'
import { Avatar, Box, Button, Stack } from '@mui/material'
import { Container } from 'reactstrap'

export const Header = () => {
  const history = useHistory()
  const { isSignedIn, photoUrl, signOut } = useAuthContext()

  // https://www.npmjs.com/package/react-google-login
  const logout = async () => {
    // update state, do the transition
    signOut()
    history.push('/login')
  }

  return (
    <nav className="navbar">
      <Container>
        <Stack width="100%" direction="row" justifyContent="flex-start" alignItems="center">
          <Box>
            <img width="120" src={thinkAndDevMobileLogo} alt="Think&amp;Dev logo" />
          </Box>
          <Box sx={{flex: 1}} />
          {isSignedIn ? (
            <>
              <MyMenu />
              <Button onClick={logout} variant="outlined" color="primary">
                <Avatar sx={{width: 32, height: 32, mr: 1}} alt="avatar" src={photoUrl || ''} />
                Logout
              </Button>
            </>
          ) : null}
        </Stack>
      </Container>
    </nav>
  )
}
