import ExecutionsTable from '../components/ExecutionsTable/ExecutionsTable'
import Page from '../components/Page'

export const Home = () => {
  return (
    <Page id="home">
      <ExecutionsTable />
    </Page>
  )
}
