import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  capitalize,
  Stack,
  MenuItem,
  TextField
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { endOfDay, format, isAfter, isBefore, startOfDay } from 'date-fns'
import { endOfToday, startOfToday, subDays } from 'date-fns/esm'
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { Container, Table } from 'reactstrap'
import { getHistoricalExecutions } from '../../services/api'
import { IExecution, IExecutionFilters } from './types'

const defaultFilters: IExecutionFilters = {
  web: '',
  executionType: '',
  from: subDays(startOfToday(), 7),
  to: endOfToday()
}

const HistoricalExecutionsTable = () => {
  const [executions, setExecutions] = useState<IExecution[]>([])
  const [filters, setFilters] = useState<IExecutionFilters>(defaultFilters)
  const [filteredExecutions, setFilteredExecutions] = useState<IExecution[]>([])

  // FUNCTIONS ----------------->

  const getNameFilterOptions = useCallback(() => {
    const existingNames = executions
      .map((execution) => execution.name)
      .filter((name, i, arr) => !!name && arr.indexOf(name) === i)
    return existingNames
  }, [executions])

  const getTypeFilterOptions = useCallback(() => {
    const existingTypes = executions
      .map((execution) => execution?.executionType)
      .filter((type, i, arr) => !!type && arr.indexOf(type) === i)
    return existingTypes
  }, [executions])

  const getFormatedExecutions = async () : Promise<IExecution[]> => {
    const response = await getHistoricalExecutions()
    if (response.status === 200) {
      const allExecutionsWithNames = response.data.map((plugin) =>
        plugin.executions.map((exec) => ({ name: plugin.name, ...exec }))
      )
      const executions = allExecutionsWithNames.flat();
      return executions;
    } else {
      return [];
    }
  }

  const filterExecutions = useCallback(() => {
    const _filteredExecutions = executions
      .filter(({ name }) => !filters.web || filters.web === name)
      .filter(
        (execution) =>
          !filters.executionType || filters.executionType === execution.executionType
      )
      .filter((execution) => !filters.from || isAfter(new Date(execution.endAt), filters.from))
      .filter((execution) => !filters.to || isBefore(new Date(execution.endAt), filters.to));

    setFilteredExecutions(_filteredExecutions)
  }, [executions, filters])

  // EFFECTS ----------------->

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      const _executions = await getFormatedExecutions()
      if (isMounted) {
        setExecutions(_executions)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    filterExecutions()
  }, [filterExecutions])

  // RENDERS ---------------->

  const renderTableHead = (): ReactNode => {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Web</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Last Run</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">
            Status Last Run
          </TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Reason</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Execution Type</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = (): ReactNode => {
    return (
      <TableBody>
        {filteredExecutions.map(({ name, ...execution }) => (
          <TableRow key={execution._id}>
            <TableCell>{name}</TableCell>
            <TableCell>{!!execution?.endAt && format(new Date(execution?.endAt), 'Pp')}</TableCell>
            <TableCell align="center">{execution?.result ? '🟢' : '🔴'}</TableCell>
            <TableCell>{execution?.reason || '--'}</TableCell>
            <TableCell>{capitalize(execution?.executionType || '')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const renderFiltersRow = (): ReactNode => {
    return (
      <Stack direction="row" spacing={2}>
        <TextField
          id="name-filter-select"
          select
          label="Web"
          value={filters?.web || ''}
          variant="filled"
          sx={{ flex: 1 }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target
            setFilters((prev) => ({ ...prev, web: value }))
          }}
        >
          <MenuItem value="">All</MenuItem>
          {getNameFilterOptions?.().map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="type-filter-select"
          select
          label="Type"
          value={filters?.executionType || ''}
          variant="filled"
          sx={{ flex: 1 }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target
            setFilters((prev) => ({ ...prev, executionType: value }))
          }}
        >
          <MenuItem value="">All</MenuItem>
          {getTypeFilterOptions?.().map((option) => (
            <MenuItem key={option} value={option}>
              {capitalize(option)}
            </MenuItem>
          ))}
        </TextField>

        <DesktopDatePicker
          label="From"
          inputFormat="MM/dd/yyyy"
          value={filters?.from}
          onChange={(value: Date | null) => {
            setFilters((prev) => {
              if (value) {
                return ({ ...prev, from: startOfDay(value) });
              } else return prev;
            })
          }}
          disableFuture
          renderInput={(params) => <TextField variant="filled" sx={{ flex: 1 }} {...params} />}
        />

        <DesktopDatePicker
          label="To"
          inputFormat="MM/dd/yyyy"
          value={filters?.to}
          onChange={(value: Date | null) => {
            setFilters((prev) => {
              if (value) {
                return ({ ...prev, to: endOfDay(value) });
              } else return prev;
            })
          }}
          disableFuture
          renderInput={(params) => <TextField variant="filled" sx={{ flex: 1 }} {...params} />}
        />
      </Stack>
    )
  }

  return (
    <Container>
      {renderFiltersRow()}
      <TableContainer component={Paper} sx={{ marginTop: 2, padding: 2 }}>
        <Table>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </Container>
  )
}

export default HistoricalExecutionsTable
