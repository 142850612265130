import OrganizationsTable from "../components/OrganizationsTable/OrganizationsTable";
import Page from "../components/Page";

const Organizations = () => {
  return (
    <Page id="organizations">
      <OrganizationsTable />
    </Page>
  );
}

export default Organizations;