import React from 'react'
import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import { OAUTH_CLIENT_ID } from '../config/constants'

export interface IAuthContext {
    isSignedIn: boolean,
    userId: Maybe<string>,
    fullName: Maybe<string>,
    email: Maybe<string>,
    photoUrl: Maybe<string>,
    oAuthTokenId: Maybe<string>,
    logIn: (jwt:any) => Promise<void>,
    logOut: () => Promise<void>,
    signIn: () => void,
    signOut: () => void,
}

interface Props {
    children: React.ReactNode
}

export const AUTH_CONTEXT_DEFAULT_VALUE = {
    isSignedIn: false,
    userId: null,
    fullName: null,
    email: null,
    photoUrl: null,
    oAuthTokenId: null,
    logIn: async () => {
        return
    },
    logOut: async () => {
        return
    },
    signIn: () => {
      return
    },
    signOut: () => {
      return
    },
}
  
  
  const AuthContext = React.createContext<IAuthContext>(AUTH_CONTEXT_DEFAULT_VALUE)

  export const AuthContextProvider = (props: Props) => {
  
    const [isSignedIn, setIsSignedIn] = React.useState<boolean>(false)
    const [userId, setUserId] = React.useState<Maybe<string>>(null)
    const [fullName, setFullName] = React.useState<Maybe<string>>(null)
    const [email, setEmail] = React.useState<Maybe<string>>(null)
    const [photoUrl, setPhotoUrl] = React.useState<Maybe<string>>(null)
    const [oAuthTokenId, setOAuthTokenId] = React.useState<Maybe<string>>(null)
    
    const logIn = async (jwt: any) => {
      setIsSignedIn(true)
      setUserId(jwt.profileObj.googleId)
      setFullName(jwt.profileObj.name)
      setPhotoUrl(jwt.profileObj.imageUrl)
      setEmail(jwt.profileObj.email)
      setOAuthTokenId(jwt.tokenId)
      return
    }
    const logOut = async () => {
      setIsSignedIn(false)
      setUserId(null)
      setFullName(null)
      setPhotoUrl(null)
      setEmail(null)
      setOAuthTokenId(null)
      return
    }

    const responseGoogleOnError = (response: any) => {
      console.error(response);
    }

    // https://blog.kommit.co/google-login-with-react-hooks-5cc502bc0845 
    const { signIn } = useGoogleLogin({
      clientId: OAUTH_CLIENT_ID, // Your clientID from Google.
      onSuccess: logIn,
      onFailure: responseGoogleOnError,
      isSignedIn: true,
      cookiePolicy: 'single_host_origin'
    })

    const { signOut } = useGoogleLogout({
      clientId: OAUTH_CLIENT_ID, // Your clientID from Google.
      onLogoutSuccess: logOut,
      cookiePolicy: 'single_host_origin'
    })

    const value = {
        isSignedIn : true,
        userId,
        fullName,
        email,
        photoUrl,
        oAuthTokenId,
        logIn,
        logOut,
        signIn,
        signOut
      }

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  }

  export const useAuthContext = (): IAuthContext => {
    const context = React.useContext(AuthContext)
    if (!context) {
      throw new Error('[useAuthContext] Hook not used under auth context provider')
    }
    return context
  }