import BigNumber from "bignumber.js"

export const truncateStringInTheMiddle = (
  str: string,
  strPositionStart: number,
  strPositionEnd: number
) => {
  if(!str) return ''
  const minTruncatedLength = strPositionStart + strPositionEnd
  if (minTruncatedLength < str.length) {
    return `${str.substr(0, strPositionStart)}...${str.substr(
      str.length - strPositionEnd,
      str.length
    )}`
  }
  return str
}

export const getSeedStageOptions = () => {
  return ['Pre-Seed', 'Seed', 'Grant', 'Series A', 'Series B', 'Initial Coin Offering'].sort()
}